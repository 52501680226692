<template>
  <div class="login-container">
    <div class="background"></div>
    <router-view  v-slot="{ Component }">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
export default {
  name: 'LoginView',
  created() {
    const { query } = this.$route;
    if (query.codeToken && (query.phone || query.email)) {
      this.$router.replace({
        name: 'LoginCode',
        query,
      }).catch(()=>{});
      return;
    }
    this.$router.push({
      name: 'LoginPhone',
      query,
    }).catch(()=>{});
  },
  watch: {
   '$store.state.user': {
     deep: true,
     handler: function (val) {
       if (val.id) this.$router.replace('/');
     }
   }
  }
};
</script>
<style lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/background.png);
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.2);
  filter: blur(3px);
}
</style>
